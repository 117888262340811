import React, { Suspense } from "react";
import {graphql} from "gatsby"

import Layout from '../components/Layout'
import Metadata from '../components/Metadata'
import Jumbotron from "../components/Jumbotron"
// import Rows from "../components/Rows"

import './blog.css'
import { Spinner } from "reactstrap";

import HomeBody from "../components/HomeBody"
import { withTranslation } from 'gatsby-plugin-react-i18next';
// const HomeBody = React.lazy(() => import("../components/HomeBody"));

// const sortByDate = movie => movie.sort((a, b) => new Date(a.movie.release) - new Date(b.movie.release))

// const getLastReleased = movies => movies.sort((a,b) => new Date(b.movie.release) - new Date(a.movie.release)).filter(i => {
//   // console.log(GENRES_TABLE[23])
//   let date = new Date(i.movie.release)
//   let now = Date.now()
//   return date <= now
// })


// const getComingSoon = movies => movies.filter(i => {
//   let date = new Date(i.movie.release)
//   let now = Date.now()
//   return date >= now
// })

// const getGenre = (genre, movies) => movies.filter(i => {
//   if(!i.movie.genres) return false
//   let num = GENRES[genre]
//   num = num.toString()
//   // console.log('Does ', i.movie.genres, 'includes ', num , i.movie.genres.includes(num))
//   return i.movie.genres.includes(num)
// })

// const getPopular = movies => movies.sort((a,b) => b.analytics.views_last_month - a.analytics.views_last_month )

// const getTrending = movies => movies.sort((a,b) => b.analytics.views_last_three_days - a.analytics.views_last_three_days )

// const getHighAvgTimes = (movies) => movies.sort((a,b) => b.analytics.avg_time_on_page - a.analytics.avg_time_on_page)
// const getHighAvgRatings = (movies) => movies.sort((a,b) => b.avg_rating - a.avg_rating)

// const getStreaming = movies => movies.filter(i => {
//   let hasMovie = false
//   i.videos.forEach(video => {
//     if (video.type === "movie") hasMovie = true
//   })
//   return hasMovie
// })

// const removeDuplicate = arr => Array.from(new Set(arr))

class HomePage extends React.Component {
  state = {
    watchOn: {
      cinetimes: false,
      netflix: false,
      primevideo: false,
      disneyplus: false,
    },
  }

  changeWatchOn = service => {
    if (service === "all") {
      // Return is state is the same
      let watchOnAll = true
      for (const key in this.state.watchOn){
        if (this.state.watchOn[key] === true){
          watchOnAll = false
        }
      }
      if (watchOnAll === true) return


      let watchOn = {}
      for (const key in this.state.watchOn){watchOn[key] = false}
      this.setState({ watchOn: watchOn })
    } else {
      this.setState({
        watchOn:{
          ...this.state.watchOn,
          [service]: !this.state.watchOn[service],
        }
      })
    }
  }

  render() {
    let { history, watchOn } = this.state
    const { t } = this.props

    history = history
    ? history.map(h => ({slug: `/t/${h.slug}`,poster_thumb: {url: h.poster} , title: h.title, id: h.slug}))
    : null

    // const streaming = this.props.data.streaming.edges;
    const streaming = null
    // let featured = [...streaming.slice(-3)]
    let featured = null

    return(
      <Layout>
        <Metadata 
          title={t('site_title')}
          description={t('site_description')}
          pathname={this.props.location.pathname}
        />
        <Jumbotron movies={featured} watchOn={watchOn} changeWatchOn={this.changeWatchOn}/>
        {/* <Suspense fallback={<div className="w-100 text-center my-5"><Spinner className="m-auto my-5" size="sm"/></div>}> */}
          <HomeBody streaming={streaming} watchOn={watchOn} slug="/browse/" infinite={true}/>
        {/* </Suspense> */}
      </Layout>
    )
  }

}

export default withTranslation()(HomePage)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


// export const query = graphql`
// {
//   streaming: allInternalMovies(
//     filter: {
// 			is_streaming: {eq: true}
//       language: {eq: "fr"},
//     }, limit: 40) {
//     edges {
//       node {
//         id
//         is_streaming
//         title
//         slug
//         movies {
//           alternative_id
//           type
//         }
//         videos {
//           type
//         }
//         poster_thumb {
//           x
//           y
//           url
//         }
//       }
//     }
//   }
// }
// `

/* 
  Rows on movie page under the video
  * People who have seen this video have also seen (if any)
    If not logged in, means -> Users that stayed long and played video on <Page> page also stayed long on <Page>

    allInternalMovies (
    filter: {
      movie:{genres: {regex: "/[^3]/"}}
    }

  Possible rows
  * Keep watching (if any)
  * Top picks(if any)
  * Popular on cinetimes
  * 5 personnalized genre rows
  * Because you watched <Video>
  * Users who watched <Video> also watched <Row of videos>
  * Oscar winners
  * Other movies with <Actor>
  *  Vos articles vus récemment et vos recommandations en vedette -- Inspiré par vos achats -- 
*/

/*
{
	bestRated: allInternalMovies (
    sort: {
      fields: avg_rating
      order: DESC
    }
    filter: {
      avg_rating: {ne: null}
      language: {eq: "fr"}
    }
    limit: 10
  ) {
    edges{
      node{
        id
        title
        slug
        poster_thumb {
          x
          y
          url
        }
      }
    }
  }
  popular: allInternalMovies (
    sort: {
      fields: [analytics___views_last_month]
      order: DESC
    }
    filter: {
      language: {eq: "fr"}
    }
    limit: 10
  ) {
    edges{
      node{
        id
        title
        slug
        poster_thumb {
          x
          y
          url
        }
      }
    }
  }
  recent: allInternalMovies (
    sort: {
      fields: [created]
      order: ASC
    }
    filter: {
      created: {ne: null}
      language: {eq: "fr"}
    }
    limit: 10
  ) {
    edges{
      node{
        id
        title
        slug
        poster_thumb {
          x
          y
          url
        }
      }
    }
  }
  streaming: allInternalMovies (
    sort: {
      fields: [analytics___views_last_month]
      order: DESC
    }
    filter: {
			videos:??
      language: {eq: "fr"}
    }
    limit: 10
  ) {
    edges{
      node{
        id
        title
        slug
        videos {
          type
        }
        poster_thumb {
          x
          y
          url
        }
      }
    }
  }
}
*/